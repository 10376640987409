var $data_ie = $("[data-ie]").data("ie"),
    $data_ie_btn = $("[data-ie-btn]").data("ie-btn"),
    $data_lang = $("html").attr("lang");

$("body").append("<div data-ie-warning data-iframe='true' data-src='http://outdatedbrowser.com/" + $data_lang + "' data-sub-html='<h4><i class=middle>" + $data_ie + "</i>&nbsp;&nbsp;&nbsp;&nbsp;<a href=# class=\"part_ui_btn_primary middle\" style=\"font-size: 0.6em\" data-ie-continue><i>" + $data_ie_btn + "</i></a></h4>'></div>'");

$("[data-ie-warning]").lightGallery({
    selector: 'this', counter: false, download: false, fullScreen: false, zoom: false
}).trigger("click");

$("[data-ie-continue]").on("click", function (e) {
    e.preventDefault();
    $(".lg-close").trigger("click");
});